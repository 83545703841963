<template>
  <div v-loading="loading" class="test-content">
    <Bread :list="bread"></Bread>
    <!-- <div class="top-image">イメージカタログ画像</div> -->
    <el-image class="top-img" :src="currQuestion.imagePath" alt="テスト画像" fit="contain" v-if="currQuestion.imagePath !== null">
      <div slot="error" class="image-slot">
      </div>
    </el-image>
    <div class="question">
      <div>問題：{{this.process + 1}}/{{this.totalProcess}}</div>
      <h3>{{currQuestion.questionTitle}}</h3>
      <br>
      <div class="content" style="white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;" v-html="currQuestion.questionContent"></div>
    </div>
    <div class="answer" v-if="currQuestion.questionType!==undefined">
      <div v-if="currQuestion.questionType === '0'">
        <div class="column-group">
          <el-radio
            v-model="value0"
            :label="q.choicesNo"
            v-for="q in orderTestQuestionChoices"
            :key="q.choicesNo"
          ><div style="white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{q.choicesContents}}</div></el-radio>
        </div>
      </div>

      <div v-if="currQuestion.questionType === '1'">
        <el-checkbox-group
          class="column-group"
          v-model="value1">
          <el-checkbox
            :label="q.choicesNo"
            v-for="q in orderTestQuestionChoices"
            :key="q.choicesNo"
          >
            <div style="white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{q.choicesContents}}</div>
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <div v-if="currQuestion.questionType === '2'">
         <el-checkbox-group
          class="column-group sort-check"
          v-model="value2"
        >
          <el-checkbox
            :label="q.choicesNo"
            v-for="q in orderTestQuestionChoices"
            :key="q.choicesNo"
          >
            <div style="white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{q.choicesContents}}</div>
            <div class="index"
              v-for="(val, key) in value2"
              v-show="val === q.choicesNo" :key="key"
            >
              {{key+1}}
            </div>
          </el-checkbox>
      </el-checkbox-group>
      </div>

      <div v-if="currQuestion.questionType === '3'">
        <div
          style="margin-top: 15px;"
          v-for="(q, index) in orderTestQuestionChoices"
          :key="q.choicesNo"
        >
          <label>問題{{index+1}}</label>
          <el-input
            placeholder=""
            v-model="value3[index]"
            show-word-limit
          />
        </div>
      </div>
    <hr>
    <el-button
    class="test-action-button"
    type="danger"
    size="medium"
    @click="changeProcess">{{process===totalProcess-1 ? '提出':'回答'}}</el-button>
    </div>
  </div>
</template>

<script>
import Bread from '@/components/Bread.vue';
import request from '@/utils/request';
import _ from 'lodash';

const DEFAULT_VALUE = {
  '0': null,
  '1': [],
  '2': [],
  '3': [],
};

export default {
  name: 'test',
  computed: {
    orderTestQuestionChoices: function () {
      return _.orderBy(this.currQuestion.testQuestionChoices, 'choicesNo')
    }
  },
  components: {
    Bread,
  },
  data() {
    return {
      studentTestId: '',
      loading: false,
      value0: DEFAULT_VALUE['0'],
      value1: DEFAULT_VALUE['1'],
      value2: DEFAULT_VALUE['2'],
      value3: DEFAULT_VALUE['3'],
      currQuestion: {},
      answerStartTime: Date.now(),
      process: 0,
      totalProcess: 0,
      questionList: [],
      bread: [],
    };
  },
  methods: {
    resetAnswer() {
      this.value0 = DEFAULT_VALUE['0'];
      this.value1 = DEFAULT_VALUE['1'];
      this.value2 = DEFAULT_VALUE['2'];
      this.value3 = [];
    },
    changeProcess() {
      this.loading = true;
      const { courseId, subjectId, unitId } = this.$route.query;
      const params = {
        courseId: courseId,
        subjectId: subjectId,
        unitId: unitId,
        studentTestId: this.studentTestId,
        testQuestionsId: this.currQuestion.testQuestionsId,
        answerStartTime: this.answerStartTime,
        questionType: this.currQuestion.questionType,
      };
      switch (this.currQuestion.questionType) {
        case '0':
          if (!this.value0) {
            this.$message({
              message: '答えを選んでください',
              type: 'error',
            });
            this.loading = false;
            return;
          }
          params.answerContent = this.value0;
          break;
        case '1':
          if (!this.value1.length) {
            this.$message({
              message: '答えを選んでください',
              type: 'error',
            });
            this.loading = false;
            return;
          }
          params.answerContent = this.value1.sort().join(',');
          break;
        case '2':
          if (!this.value2.length) {
            this.$message({
              message: '答えを選んでください',
              type: 'error',
            });
            this.loading = false;
            return;
          }
          params.answerContent = this.value2.join(',');
          break;
        case '3':
          if (!this.value3.length) {
            this.$message({
              message: '答えを入力してください',
              type: 'error',
            });
            this.loading = false;
            return;
          }
          params.answerContent = this.value3.join(',');
          break;
        default:
          break;
      }
      if (this.process === this.totalProcess - 1) {
        params.testCount = this.totalProcess;
        request('submitTestLast', params).then((res) => {
          window.testResult = res;
          this.loading = false;
          this.goToResult();
        });
      } else {
        request('submitTest', params).then(() => {
          this.process += 1;
          this.loading = false;
          this.setCurrQuestion(this.questionList[this.process]);
        });
      }
    },
    goToResult() {
      const { courseId, subjectId, unitId, materialId } = this.$route.query;
      this.$router.push({
        path: `/test/${this.studentTestId}/result?courseId=${courseId}&subjectId=${subjectId}`,
        query: {unitId:unitId, materialId: materialId }
      });
    },
    goUnit() {
      const { courseId, subjectId, unitId } = this.$route.query;
      this.$router.push({
        path: `/unit/${unitId}/${courseId}/${subjectId}`,
      }).catch(() => {});
    },
    setCurrQuestion(config) {
      const { studentTestId, testQuestion = {} } = config;

      this.studentTestId = studentTestId;
      this.currQuestion = testQuestion;
      this.answerStartTime = Date.now();
      this.resetAnswer();
    },
    confirmSave (event) {
      event.returnValue = "テストを中断してもよろしいでしょうか？";
    },
  },
  created() {
    const { courseId, subjectId, unitId } = this.$route.query;

    request('getTestList', {
      courseId: courseId,
      subjectId: subjectId,
      unitId: unitId,
    }).then((res) => {
      const {
        courseName,
        subjectName,
        unitName,
        testQuestionResponceResource: questionList,
      } = res;

      this.totalProcess = questionList.length;
      this.questionList = questionList;
      this.setCurrQuestion(questionList[0]);

      this.bread = [
        {
          path: '/',
          name: 'トップページ',
        },
        {
          path: `/course/${courseId}`,
          name: courseName,
        },
        {
          path: `/subject/${subjectId}/${courseId}`,
          name: subjectName,
        },
        {
          path: `/unit/${unitId}/${courseId}/${subjectId}`,
          name: unitName,
        },
        {
          name: 'テスト',
        },
      ];
    });

    window.addEventListener("beforeunload", this.confirmSave);
  },
  destroyed () {
    window.removeEventListener("beforeunload", this.confirmSave);
  },
  beforeRouteLeave (to, from, next) {
    // テスト画面からテスト結果画面に遷移する場合、確認ダイアログを表示しない
    if (from.name === "Test" && to.name !== "TestResult") {
      if (window.confirm("テストを中断してもよろしいでしょうか？")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style lang="less">
html {
  font-size:17px;
  line-height:1.5em;
}
.el-message--error {
  top: 150px!important;
}
@import "../styles/test.less";
.question {
  margin: 30px 0;
  text-align: left;
  width: 100%;
  h3 {
    line-height: 1.725;
    font-weight: 700;
  }
  p {
    color: #666;
    margin: 10px 0 ;
  }
}
.answer {
  width: 100%;
  .title {
    margin: 16px 0;
    text-align: center;
  }
}
.input-area {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  label {
    white-space: nowrap;
  }
  .el-textarea {
    max-width: 450px;
  }
}
.column-group {
  display: flex;
  flex-direction: column;
  &.sort-check {
    .el-checkbox__inner::after {
      border: 0px;
    }
  }
  .el-radio,.el-checkbox {
    padding: 20px 12px;
    position: relative;
    display: flex;
    align-items: flex-start;
    border: 1px solid @borderColor;
  }
  .el-radio, .el-radio__input, .el-checkbox, .el-checkbox__input {
    white-space: normal;
  }
  label {
    margin: -1px 0 0 0;
  }
  .el-checkbox_label {
    position: r_elative;
  }
  .index {
    position: absolute;
    left: 15px;
    top: 18px;
    z-index: 3;
    color: #fff;
  }
}

.test-content {
  margin: 30px 0;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  padding:40px 16px;
  padding-top: 0;
  .top-image {
    border: 1px solid @borderColor;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    // width: 100%;
    // max-width: 500px;
    justify-content: center;
    border-radius: 6px;
  }
  .test-action-button {
    font-size: 16px;
    padding: 12px 40px;
    margin-top: 30px;
  }
}
</style>
